/* eslint-disable camelcase */
import { Fragment } from "react";
import styles from "./FooterBody.module.scss";
import SocialIcons from "../SocialIcons/SocialIcons";

interface FooterBodyProps {
	data: any[];
}

const FooterBody = ({ data }: FooterBodyProps) => {
	if (!data) return null;
	const handleClick = (e: any) => {
		if (window.matchMedia("(max-width: 877px)").matches) {
			var elm = e.target.nextSibling;
			var computedStyle = getComputedStyle(elm);

			if (computedStyle["display"] == "block") {
				elm.style.display = "none";
			} else {
				elm.style.display = "block";
			}
		}
	};

	window.addEventListener("resize", function () {
		var elms = document.getElementsByClassName(
			"footerElements"
		) as HTMLCollectionOf<HTMLElement>;

		if (elms.length > 0) {
			if (window.matchMedia("(max-width: 877px)").matches) {
				for (var i = 0; i < elms.length; i++) {
					elms[i].style.display = "none";
				}
			} else {
				for (var i = 0; i < elms.length; i++) {
					elms[i].style.display = "block";
				}
			}
		}
	});

	return (
		<>
			<div className={`${styles.refListWrap}`}>
				{data.map(({ content_type, label, links, references }, index) => {
					switch (content_type) {
						case "referenceList":
							return (
								<div
									key={index}
									className={`${styles.connectContainer}`}
								>
									<p>Connect With Us</p>
									{references.map(
										({ content_type, links, text }, index) => {
											switch (content_type) {
												case "imageLinkList":
													return (
														<div
															key={index}
															className={`${styles.socialIcons}`}
														>
															<SocialIcons icons={links} />
														</div>
													);
												case "text":
													return <h4 key={index}>{text}</h4>;
											}
										}
									)}
								</div>
							);
						case "textLinkList":
							if (!links) return null;
							return (
								<div
									key={index}
									className={`${styles.textLinkListWrap}`}
								>
									<p
										onClick={handleClick}
										onKeyPress={handleClick}
										role="presentation"
									>
										{label}
									</p>
									<ul className="footerElements">
										{links.map(({ label, destinationUrl }, index) => {
											return (
												<Fragment key={index}>
													<a href={destinationUrl}>
														<li>{label}</li>
													</a>
												</Fragment>
											);
										})}
									</ul>
								</div>
							);
					}
				})}
				<div className={`${styles.copyrightText}`}>
					© Redcon1, LLC {new Date().getFullYear()}. All rights reserved.
				</div>
			</div>
		</>
	);
};

export default FooterBody;
