import { useState, useEffect } from "react";
import styles from "./Buttons.module.scss";
import { useRouter } from "next/router";
import LoadingButton from "./LoadingButton";
import "external-svg-loader";

interface CustomButtonProps {
	text: string;
	destination?: string;
	variant?: "outline" | "fill";
	color?: "primary" | "danger" | "light" | "disabled";
	contentType?: string;
	callBack?: Function;
	loading?: boolean;
	svg?: string;
	width?: "normal" | "full";
	className?: string;
	disabled?: boolean;
}

const Buttons = ({
	variant = "outline",
	text,
	destination = null,
	color = "primary",
	contentType = null,
	callBack,
	loading = false,
	svg = null,
	width = "normal",
	className = null,
	disabled = false,
}: CustomButtonProps): JSX.Element => {
	const [mounted, setMounted] = useState(false);
	const router = useRouter();

	useEffect(() => {
		setMounted(true);
	}, []);

	const handleOnClick = () => {
		if (destination) {
			return router.push(destination);
		}

		return callBack();
	};

	const setColor = (color: string) => {
		switch (color) {
			case "primary":
				return styles.primary;

			case "disabled":
				return styles.outOfStock;

			case "danger":
				return styles.danger;

			case "light":
				return styles.light;

			default:
				return styles.danger;
		}
	};

	if (!mounted) {
		return null;
	}

	return (
		<button
			disabled={loading || disabled}
			onClick={handleOnClick}
			className={`${styles.buttonCustom} ${
				variant === "fill" ? styles.fill : styles.outline
			} ${setColor(color)} ${className}
			`}
			type="button"
			style={{ width: width === "full" ? "100%" : "" }}
		>
			{contentType === "imageLink" ? (
				<>
					{variant === "fill" ? (
						<div className={styles.imgText}>
							<svg
								data-src={svg}
								fill="currentColor"
								width="25px"
								height="25px"
								style={{
									color: "white",
								}}
							/>
							{text}
						</div>
					) : (
						<div className={styles.imgText}>
							<svg
								data-src={svg}
								fill="currentColor"
								width="25px"
								height="25px"
								style={{
									color: "black",
								}}
							/>
							{text}
						</div>
					)}
				</>
			) : (
				<>{loading ? <LoadingButton /> : <>{text}</>}</>
			)}
		</button>
	);
};

export default Buttons;
