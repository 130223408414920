import React from "react";
import App from "next/app";
import "../styles/globals.scss";
import TopNav from "components/TopNav/TopNav";
import Navbar from "components/Navbar/Navbar";

import Footer from "components/Footer/Footer";
import { AppProps } from "next/app";

import $nacelle from "../services/nacella";
import { minimizeData } from "../helpers/minimizeData";
import { GlobalProvider } from "context/global/GlobalProvider";
import Script from "next/script";

import Notifications from "@/components/Notifications/Notifications";

import { NotificationProvider } from "context/notification/NotificationProvider";
import GeoRedirectBar from "@/components/GeoRedirectBar/GeoRedirectBar";
import { UiProvider } from "context/ui";
import { CartProvider } from "context/cart";
import { AccountProvider } from "context/account/AccountProvider";

interface AppCustomProps extends AppProps {
	content: any;
	footerContent: any;
}
function MyApp({
	Component,
	pageProps,
	content,
	footerContent,
}: AppCustomProps): JSX.Element {
	const NewComponent: any = Component;
	return (
		<>
			<NotificationProvider>
				{/* sundaysky */}
				<Script
					type="text/javascript"
					src="//cdn1-res.sundaysky.com/vop/v2/t.js"
					async={true}
				/>
				{/* Google Tag Manager */}
				<Script
					type="text/javascript"
					async={true}
					src="https://cdn.swellrewards.com/loader/rjEHWea5FGGt26sJDJsnUw.js"
				/>
				<Script
					type="text/javascript"
					src="https://static.affiliatly.com/v3/affiliatly_subdomain.js?affiliatly_code=AF-104645"
				/>
				<Script
					id="text/javascript"
					src="https://static.ordergroove.com/6e14b0000cc411eba9cdbc764e10b970/main.js"
				/>
				<Script
					id="gorgias-chat-widget-install-v2"
					src="https://config.gorgias.chat/gorgias-chat-bundle-loader.js?applicationId=20295"
				/>
				<React.StrictMode>
					<AccountProvider>
						<CartProvider>
							<GlobalProvider>
								<UiProvider>
									<div className="main-header">
										<GeoRedirectBar />
										<TopNav topNavData={content} />
										<Navbar {...content} />
									</div>
									<main className="main-content">
										<NewComponent {...pageProps} />
									</main>
								</UiProvider>
							</GlobalProvider>
						</CartProvider>
						<Footer {...footerContent} />
					</AccountProvider>
				</React.StrictMode>
				<Notifications />
			</NotificationProvider>
		</>
	);
}

MyApp.getInitialProps = async (ctx) => {
	const appProps = await App.getInitialProps(ctx);
	let header: any = null;
	let footer: any = null;
	try {
		footer = await $nacelle.data.content({
			handle: "footer_us_retail_test",
			type: "referenceList",
		});
		header = await $nacelle.data.content({
			handle: "header_us_retail",
			type: "header",
		});
	} catch (error) {
		/* eslint no-console: "off" */
		console.warn(`no page with handle 'header_us_retail' found.`);
		return { props: {} };
	}

	return {
		...appProps,
		content: minimizeData(header),
		footerContent: minimizeData(footer),
	};
};

export default MyApp;
