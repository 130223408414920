import {
	FunctionComponent,
	useReducer,
	useMemo,
	useContext,
	useEffect,
	useCallback,
} from "react";
import { GlobalContext, globalReducer } from ".";
import { IGlobalStatus } from "interfaces/globalStatus.interfaces";
import { IProduct } from "interfaces/products.interfaces";
import { CartContext } from "context/cart/CartContext";

export interface GlobalState {
	redcon1: IGlobalStatus;
	product: IProduct | false;
}

const GLOBAL_INITIAL_STATE: GlobalState = {
	redcon1: {
		cart: {
			cartTotal: 0,
			cartQty: 0,
		},
		product: {
			discountable: null,
		},
	},
	product: false,
};

interface Props {}

export const GlobalProvider: FunctionComponent<Props> = ({ children }) => {
	const { cart } = useContext(CartContext);
	// eslint-disable-next-line no-unused-vars
	const [state, dispatch] = useReducer(
		globalReducer,
		GLOBAL_INITIAL_STATE,
		() => {
			if (typeof window !== "undefined") {
				const localData = localStorage.getItem("global");
				return localData
					? JSON.parse(localData)
					: {
							redcon1: {
								cart: {
									cartTotal: 0,
									cartQty: 0,
								},
								product: {
									discountable: null,
								},
							},
							product: false,
					  };
			}
		}
	);

	useMemo(() => {
		if (state?.redcon1) {
			localStorage.setItem("global", JSON.stringify(state));
			window["redcon1"] = state?.redcon1;
		}
	}, [state]);

	const updateGlobalData = useCallback(
		(
			cartTotal: number,
			cartQty: number,
			discountableValue: boolean | null
		) => {
			dispatch({
				type: "[Global] - Update Global Status",
				payload: {
					cart: { cartTotal, cartQty },
					product: {
						discountable: discountableValue,
					},
				},
			});
		},
		[]
	);

	const setProduct = (product: IProduct | false) => {
		dispatch({
			type: "[Global] - Update Product",
			payload: product,
		});
	};

	useEffect(() => {
		var cartTotal: number = 0;
		var cartQuantity: number = 0;
		var discountable: boolean = false;

		cart.map((item) => {
			cartTotal += item.price * item.quantity;
			cartQuantity += item.quantity;
		});

		if (state?.product) {
			discountable = state.product.tags.includes("Discountable");
		}

		updateGlobalData(+cartTotal.toFixed(2), cartQuantity, discountable);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cart, state?.product]);

	return (
		<GlobalContext.Provider
			value={{
				...state,
				updateGlobalData,
				setProduct,
			}}
		>
			{children}
		</GlobalContext.Provider>
	);
};
