export function minimizeData(data) {
	if (typeof data === "string") {
		try {
			data = JSON.parse(data);
		} catch (e) {
			return null;
		}
	}
	let minimal_data = {};
	if (!("fields" in data)) {
		return null;
	}
	for (let prop of ["title", "handle"]) {
		if (prop in data) {
			minimal_data[prop] = data[prop];
		}
	}
	if ("file" in data["fields"] && "url" in data["fields"]["file"]) {
		let url = data["fields"]["file"]["url"];
		if (url.substring(0, 2) === "//") {
			url = "https:" + url;
		}
		minimal_data["url"] = url;
		return minimal_data;
	}
	if ("type" in data) {
		minimal_data["content_type"] = data["type"];
	} else {
		minimal_data["content_type"] = data["sys"]["contentType"]["sys"]["id"];
	}
	for (let field_name in data["fields"]) {
		if (
			typeof data["fields"][field_name] !== "object" ||
			data["fields"][field_name] === null
		) {
			minimal_data[field_name] = data["fields"][field_name];
			continue;
		}
		if (Array.isArray(data["fields"][field_name])) {
			if (
				data["fields"][field_name].length === 0 ||
				typeof data["fields"][field_name][0] !== "object"
			) {
				minimal_data[field_name] = data["fields"][field_name];
			} else {
				minimal_data[field_name] = [];
				for (let item of data["fields"][field_name]) {
					let d = minimizeData(item);
					if (d !== null) {
						minimal_data[field_name].push(d);
					}
				}
			}
			continue;
		}
		if (!("sys" in data["fields"][field_name])) {
			continue;
		} else {
			minimal_data[field_name] = minimizeData(data["fields"][field_name]);
		}
	}
	return minimal_data;
}
