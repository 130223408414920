import Link from "next/link";
import styles from "./MainNavigation.module.scss";

interface MainNavigationProps {
	navBarTextLinks: any[];
}

const MainNavigatio = ({
	navBarTextLinks,
}: MainNavigationProps): JSX.Element => {
	return (
		<>
			{navBarTextLinks && (
				<ul className={styles.mainNavigation}>
					{navBarTextLinks.map(({ destinationUrl, title }) => (
						<li key={title}>
							<Link prefetch={false} href={destinationUrl}>
								<a>{title}</a>
							</Link>
						</li>
					))}
				</ul>
			)}
		</>
	);
};

export default MainNavigatio;
