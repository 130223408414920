import { FunctionComponent, useState, useEffect } from "react";
import styles from "./Footer.module.scss";
import FooterBody from "../FooterBody/FooterBody";

interface FooterProps {
	references: any[],
}

const Footer: FunctionComponent<FooterProps> = ({ references }: FooterProps) => {
	const [mounted, setMounted] = useState(false);
	useEffect(() => {
		setMounted(true);
	}, []);

	return (
		<>
			{mounted && (
				<footer className={`${styles.footer}`}>
					<div className={styles.wrap}>
						<FooterBody data={references} />
					</div>
				</footer>
			)}
		</>
	)
};

export default Footer;