import styles from "./SearchResult.module.scss";
import { motion, Variants } from "framer-motion";
import { useRouter } from "next/router";
import RC1Image from "../RC1Image/RC1Image";
import { currencyFormat } from "utils/currencyFormat";

interface SearchResultsProps {
	items: any[];
	callBack: Function;
}

const itemChild: Variants = {
	hidden: {
		opacity: 0,
		x: -20,
	},
	show: {
		opacity: 1,
		x: 0,
		transition: {
			duration: 0.5,
		},
	},
};

const SearchResults = ({ items, callBack }: SearchResultsProps) => {
	const router = useRouter();

	const handleGoTo = (handle: string) => {
		router.push(`/products/${handle}`);
		callBack();
	};

	if (items.length === 0) return null;

	return (
		<div className={styles.wrapResult}>
			<div className={styles.containerResults}>
				{items.map((item) => (
					<motion.div
						key={item.key}
						className={styles.resultsItem}
						variants={itemChild}
						onClick={() => handleGoTo(item.handle)}
						whileHover={{
							scale: 1.1,
							transition: {
								type: "spring",
								stiffness: 300,
							},
						}}
					>
						<div className={styles.containerImg}>
							<RC1Image
								src={item.image}
								alt="product"
								width={70}
								height={70}
								layout="fill"
								objectFit="contain"
							/>
						</div>
						<div className={styles.content}>
							<p
								dangerouslySetInnerHTML={{ __html: item.title }}
							/>
							<span>{currencyFormat(item.price)}</span>
						</div>
					</motion.div>
				))}
			</div>
		</div>
	);
};

export default SearchResults;
