// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
	dsn:
		SENTRY_DSN ||
		"https://0cfe0740684f425295ce089d5ef5b974@o951937.ingest.sentry.io/5901101",
	// Adjust this value in production, or use tracesSampler for greater control
	tracesSampleRate: 0,
	integrations: [
		new Sentry.BrowserTracing({
			tracingOrigins: ["http://localhost:3000", process.env.VERCEL_URL],
		}),
	],
	// ...
	// Note: if you want to override the automatic release value, do not set a
	// `release` value here - use the environment variable `SENTRY_RELEASE`, so
	// that it will also get attached to your source maps
});
