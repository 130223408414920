import Link from "next/link";
import styles from "./RightNavigation.module.scss";
import CartRightNavigation from "./CartRightNavigation";
import SearchSvg from "public/search.svg";

interface RightNavigationProps {
	cornerTextLinks: any[];
}

const RightNavigation = ({
	cornerTextLinks,
}: RightNavigationProps): JSX.Element => {
	return (
		<ul className={styles.rightNavigation}>
			<li>
				<Link prefetch={false} href="/search">
					<a className={styles.search}>
						<SearchSvg width={22} height={22} />
					</a>
				</Link>
			</li>
			<li>
				<CartRightNavigation />
			</li>
			{cornerTextLinks &&
				cornerTextLinks.map(({ destinationUrl, title }) => (
					<li className="d-none d-md-block" key={title}>
						<Link prefetch={false} href={destinationUrl}>
							<a>{title}</a>
						</Link>
					</li>
				))}
		</ul>
	);
};

export default RightNavigation;
