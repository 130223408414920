import { FunctionComponent, useReducer, useEffect } from "react";
import { UiContext, uiReducer } from "./";
import $nacelle from "@/services/nacella";
import { ISwatches } from "interfaces/swatches";

export interface UiState {
	swatches: ISwatches[];
	statusMenu: boolean;
	loading: boolean;
}

const UI_INITIAL_STATE: UiState = {
	swatches: [],
	statusMenu: false,
	loading: false,
};

interface Props {}

export const UiProvider: FunctionComponent<Props> = ({ children }) => {
	const [state, dispatch] = useReducer(uiReducer, UI_INITIAL_STATE);

	useEffect(() => {
		const findAllSwatches = async () => {
			try {
				let response = await $nacelle.data.content({
					handle: `us_retail-global-properties`,
					type: "globalProperties",
				});
				dispatch({
					type: "[Ui] - Updated swatch data",
					payload: response?.fields?.swatchData,
				});
			} catch (error) {
				/* eslint no-console: "off" */
				dispatch({
					type: "[Ui] - Updated swatch data",
					payload: [],
				});
				console.warn(`no page with handle found.`);
			}
		};
		findAllSwatches();
	}, []);

	const startLoading = () => {
		dispatch({ type: "[Ui] - Start loading" });
	};
	const stopLoading = () => {
		dispatch({ type: "[Ui] - Stop loading" });
	};

	const toggleMenu = (status: boolean) => {
		dispatch({ type: "[Ui] - Toggle menu", payload: status });
	};

	return (
		<UiContext.Provider
			value={{
				...state,
				// method
				startLoading,
				stopLoading,
				toggleMenu,
			}}
		>
			{children}
		</UiContext.Provider>
	);
};
