import Link from "next/link";
import styleComponent from "./ImageLink.module.scss";

interface ImagesLinkProps {
	imageLinks: any[];
}

const ImagesLink = ({ imageLinks }: ImagesLinkProps): JSX.Element => {
	return (
		<ul className={styleComponent.imageLink}>
			{imageLinks &&
				imageLinks.map(
					({ destinationUrl, image: { url }, label, title }) => (
						<li key={label}>
							<Link prefetch={false} href={destinationUrl}>
								<a>
									<img src={url} alt={title} />
								</a>
							</Link>
						</li>
					)
				)}
		</ul>
	);
};

export default ImagesLink;
