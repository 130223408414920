import { useContext } from "react";
import styles from "./Notifications.module.scss";
import CheckIcon from "../../public/success.svg";
import ErrorIcon from "../../public/error.svg";
import AlertIcon from "../../public/exclamation.svg";
import { motion, AnimatePresence } from "framer-motion";
import { NotificationContext } from "context/notification/NotificationContext";
import { NOTIFICATION } from "context/notification";

export default function Notifications() {
	const { notifications } = useContext(NotificationContext);

	const defaultStyles = {
		[NOTIFICATION.ALERT]: {
			backgroundColor: "#d13239",
			border: "1px solid rgba(241, 6, 6, 0.81)",
			color: "white",
			boxShadow: "0px 0px 2px #ff0303",
		},
		[NOTIFICATION.ERROR]: {
			backgroundColor: "#d13239",
			border: "1px solid rgba(241, 6, 6, 0.81)",
			color: "white",
			boxShadow: "0px 0px 2px #ff0303",
		},
		[NOTIFICATION.SUCCESS]: {
			backgroundColor: "rgba(7, 149, 66, 1)",
			border: "1px solid rgba(36, 241, 6, 0.46)",
			color: "white",
			boxShadow: "0px 0px 2px #259c08",
		},
	};

	return (
		<>
			<div className={styles.notificationContainer}>
				<AnimatePresence>
					{notifications.map((notification, i) => (
						<motion.div
							key={i}
							initial="hidden"
							animate="visible"
							variants={{
								hidden: {
									opacity: 0,
									x: 12,
								},
								visible: {
									opacity: 1,
									x: 0,
									transition: {
										delay: 0.1,
									},
									type: "spring",
								},
							}}
							exit={{
								x: 100,
								opacity: 0,
								transition: {
									delay: 0.1,
									duration: 1,
									type: "spring",
								},
							}}
						>
							<div
								id={notification.id}
								key={i}
								className={styles.notificationToast}
								style={defaultStyles[notification.variant]}
							>
								<div className={styles.row}>
									<div className={styles.col}>
										<div
											className={styles.notificationImage}
										>
											<div className={styles.svgWrapper}>
												{notification.variant ===
												NOTIFICATION.ERROR ? (
													<ErrorIcon />
												) : notification.variant ===
												  NOTIFICATION.SUCCESS ? (
													<CheckIcon />
												) : (
													<AlertIcon />
												)}
											</div>
										</div>
									</div>
									<div className={styles.col}>
										<p className={styles.notificationTitle}>
											<b>{notification.message}</b>
										</p>
										<p className="notification-message">
											<b>{notification.description}</b>
										</p>
									</div>
								</div>
							</div>
						</motion.div>
					))}
				</AnimatePresence>
			</div>
		</>
	);
}
