import { FunctionComponent, useReducer, useMemo } from "react";
import { AccountContext, accountReducer } from ".";
import { IUser } from "interfaces";
import Cookies from "js-cookie";

export interface AccountState {
	user: IUser | null;
}

const ACCOUNT_INITIAL_STATE: AccountState = {
	user: null,
};

interface Props {}

// eslint-disable-next-line react/prop-types
export const AccountProvider: FunctionComponent<Props> = ({ children }) => {
	const [state, dispatch] = useReducer(accountReducer, ACCOUNT_INITIAL_STATE);

	useMemo(() => {
		if (Cookies.get("userData")) {
			let userData: IUser = JSON.parse(Cookies.get("userData"));
			if (userData) {
				window["analytics"].identify(userData.userID, {
					name: userData.name,
					email: userData.email,
				});
			}
			dispatch({
				type: "[Account] - Fetch user data",
				payload: userData,
			});
		}
	}, []);

	return (
		<AccountContext.Provider
			value={{
				...state,
			}}
		>
			{children}
		</AccountContext.Provider>
	);
};
