import styles from "./MenuList.module.scss";
import { Fragment } from "react";
import MenuItems from "../MenuItems/MenuItems";

interface MenuListProps {
	data: any[];
}

const MenuList = ({ data }: MenuListProps): JSX.Element => {
	return (
		<ul className={styles.leftMenuItem}>
			{data &&
				data.length &&
				data.map(({ title, label, links }) => {
					return (
						<Fragment key={label}>
							<li className={styles.leftMenuHeader}>{title}</li>
							{links?.length !== 0 &&
								links.map((item: any) => (
									<li key={item.label}>
										<MenuItems item={item} />
									</li>
								))}
						</Fragment>
					);
				})}
		</ul>
	);
};

export default MenuList;
