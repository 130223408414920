import { IProduct } from "@/types/products.interfaces";
import { IGlobalStatus } from "interfaces/globalStatus.interfaces";
import { GlobalState } from ".";

type GlobalActionType =
	| { type: "[Global] - Update Global Status"; payload: IGlobalStatus }
	| { type: "[Global] - Update Product"; payload: IProduct | false };

export const globalReducer = (
	state: GlobalState,
	action: GlobalActionType
): GlobalState => {
	switch (action.type) {
		case "[Global] - Update Global Status":
			return {
				...state,
				redcon1: {
					cart: {
						cartTotal: action.payload.cart.cartTotal,
						cartQty: action.payload.cart.cartQty,
					},
					product: {
						discountable: action.payload.product.discountable,
					},
				},
			};
		case "[Global] - Update Product":
			return {
				...state,
				product: action.payload
			};
		default:
			return state;
	}
};
