import Link from "next/link";
import RC1Image from "../RC1Image/RC1Image";
import styles from "./Brand.module.scss";

interface BrandPropd {
	logo: any;
	altText: any;
}

const Brand = ({ logo, altText }: BrandPropd): JSX.Element => {
	return (
		<>
			{logo && (
				<div className={styles.brandImg}>
					<Link prefetch={false} href="/">
						<a>
							<RC1Image
								layout="fill"
								objectFit="fill"
								src={logo.url + "?fm=webp"}
								alt={altText}
								priority={true}
							/>
						</a>
					</Link>
				</div>
			)}
		</>
	);
};

export default Brand;
