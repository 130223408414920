import Link from "next/link";
import { UiContext } from "context/ui";
import styles from "./MenuItems.module.scss";
import { useContext } from "react";
import Cookies from "js-cookie";
import "external-svg-loader";

interface MenuItemsProps {
	item: any;
}

const MenuItems = ({ item }: MenuItemsProps): JSX.Element => {
	const { toggleMenu } = useContext(UiContext);

	return (
		<Link prefetch={false} href={item?.destinationUrl || "/"}>
			<a
				className={styles.link}
				onClick={() => {
					if (
						item?.destinationUrl ==
						"https://accounts.redcon1.com/account/logout"
					) {
						Cookies.remove("userData", {
							expires: 30,
							domain: "redcon1.com",
						});
					}

					toggleMenu(false);
				}}
				role="button"
				onKeyPress={() => {
					if (
						item?.destinationUrl ==
						"https://accounts.redcon1.com/account/logout"
					) {
						Cookies.remove("userData", {
							expires: 30,
							domain: "redcon1.com",
						});
					}

					toggleMenu(false);
				}}
				tabIndex={0}
			>
				{item?.image && (
					<svg data-src={item.image.url ? item.image.url : null} />
				)}
				{item.title}
			</a>
		</Link>
	);
};

export default MenuItems;
