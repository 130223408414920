import { useState, useEffect, useContext } from "react";
import styles from "./Search.module.scss";
import SearchResults from "../SearchResult/SearchResult";
import SearchIcon from "../SearchIcon/SearchIcon";
import Cookies from "js-cookie";
import { useRouter } from "next/router";
import { searchClient } from "@/services/angolia";
import { UiContext } from "context/ui";

const index = searchClient.initIndex("rc1_us_retail_shopify_products");

let filterString =
	'NOT tags: "Alpha Product" AND NOT tags:"Tier Operator Product" AND NOT tags:"Hidden"';

const Search = () => {
	const router = useRouter();
	const [result, setResult] = useState(null);
	const [query, setQuery] = useState("");
	const { toggleMenu, statusMenu } = useContext(UiContext);
	const performanceSearch = async (value: any) => {
		try {
			let userCookies = Cookies.get("userData");
			if (userCookies) {
				let cookieString = userCookies.replace(/\\/g, "");
				let jsonCookies = JSON.parse(cookieString);
				if (jsonCookies.tags.includes("Alpha Team Member")) {
					filterString =
						'NOT tags:"Tier Operator Product" AND NOT tags: "Hidden"';
					if (jsonCookies.tags.includes("Tier Operator")) {
						filterString = "NOT tags:'Hidden'";
					}
				} else if (jsonCookies.tags.includes("Tier Operator")) {
					filterString =
						'NOT tags:"Alpha Product" AND NOT tags: "Hidden"';
					if (
						!jsonCookies.tags.includes("Tier Operator Fulfillment")
					) {
						filterString =
							'NOT tags:"Alpha Product" AND NOT tags: "Hidden" AND NOT tags: "Tier Operator Fulfillment Product"';
					}
				}
			}
		} catch (e) {}

		const { hits } = await index.search(value, {
			filters: filterString,
			hitsPerPage: 10,
			distinct: 1,
		});

		const results = hits.map((hit: any) => {
			const {
				objectID: key,
				image,
				handle,
				price,
				_highlightResult,
			} = hit;
			const {
				title: { value: title },
			} = _highlightResult;
			return { key, image, title, handle, price };
		});
		setResult(results);
	};

	const hadleChange = (e: any) => {
		const { value } = e.target;
		setQuery(value);
		value === "" ? setResult(null) : performanceSearch(value);
	};

	const searchMe = () => {
		router.push({ pathname: "/search", query: { q: query } });
	};

	const clear = () => {
		setResult(null);
		setQuery("");
		toggleMenu(false);
	};
	const clearSearch = () => {
		setResult(null);
		setQuery("");
	};

	useEffect(() => {
		if (statusMenu === false) {
			setQuery("");
			setResult(null);
		}
	}, [statusMenu]);

	return (
		<div className={styles.wrapSearch}>
			<div className={`${styles.search}  m-3`}>
				<input
					className="form-control"
					type="text"
					placeholder="Search..."
					onChange={hadleChange}
					value={query}
				/>
				{result && (
					<button
						className={styles.clearBtn}
						type="button"
						onClick={clearSearch}
					>
						X
					</button>
				)}
				<div
					className={styles.searchBtn}
					onClick={() => searchMe()}
					onKeyPress={() => searchMe()}
					tabIndex={0}
					role="button"
				>
					<SearchIcon />
				</div>
			</div>
			{result && (
				<SearchResults items={result} callBack={() => clear()} />
			)}
		</div>
	);
};

export default Search;
