import React, { Fragment, useEffect, useRef, useState } from "react";
import Link from "next/link";
import styles from "./SlideCustomText.module.scss";
import useSortDataByDate from "@/hooks/useSortDataByDate";

interface SlideCustomTextProps {
	textBannerData: Array<any>;
}

const SlideCustomText = ({
	textBannerData,
}: SlideCustomTextProps): JSX.Element => {
	const slideshow = useRef(null);
	const mainContainer = useRef(null);
	const linkContainer = useRef(null);
	const intervalSlideShow = useRef(null);
	const [mounted, setMounted] = useState(false);
	const [sortByDateSliders] = useSortDataByDate(textBannerData);

	const setWidth = () => {
		if (slideshow.current?.children.length) {
			if (slideshow.current.children.length > 0) {
				const width = linkContainer.current.children[0].offsetWidth;
				slideshow.current.style.width = `calc(${width}px + 10%)`;
			}
		}
	};

	const next = () => {
		if (slideshow.current?.children.length) {
			if (slideshow.current.children.length > 0) {
				const firstElement = slideshow.current.children[0];
				const size = slideshow.current.children[0].offsetWidth;

				slideshow.current.style.transition = `300ms ease-out all`;
				slideshow.current.style.transform = `translateX(-${size}px)`;

				const transition = () => {
					slideshow.current.style.transition = `none`;
					slideshow.current.style.transform = `translateX(0)`;

					slideshow.current.appendChild(firstElement);

					slideshow.current.removeEventListener(
						"transitionend",
						transition
					);
				};

				slideshow.current.addEventListener("transitionend", transition);
			}
		}
	};

	const prev = () => {
		if (slideshow.current.children.length > 0) {
			const index = slideshow.current.children.length - 1;
			const latElement = slideshow.current.children[index];
			slideshow.current.insertBefore(
				latElement,
				slideshow.current.firstChild
			);

			slideshow.current.style.transition = "none";

			const size = slideshow.current.children[0].offsetWidth;
			slideshow.current.style.transform = `translateX(-${size}px)`;

			setTimeout(() => {
				slideshow.current.style.transition = `300ms ease-out all`;
				slideshow.current.style.transform = `translateX(0)`;
			}, 30);
		}
	};

	useEffect(() => {
		setMounted(true);
		return () => setMounted(false);
	}, []);

	useEffect(() => {
		if (mounted) {
			setWidth();
			intervalSlideShow.current = setInterval(() => {
				next();
			}, 5000);
			mainContainer.current.addEventListener("mouseenter", () => {
				clearInterval(intervalSlideShow.current);
			});
			mainContainer.current.addEventListener("mouseleave", () => {
				intervalSlideShow.current = setInterval(() => {
					next();
				}, 5000);
			});
		}
		return () => clearInterval(intervalSlideShow.current);
	}, [mounted]);

	if (!sortByDateSliders) return null;

	return (
		<>
			{mounted && (
				<div className={styles.mainContainer} ref={mainContainer}>
					<div
						className={styles.prev}
						onClick={prev}
						role="button"
						onKeyPress={prev}
						tabIndex={0}
					>
						&larr;
					</div>
					<div className={styles.showSlide} ref={slideshow}>
						{sortByDateSliders.map((item, index) => (
							<div
								key={index}
								className={styles.slide}
								ref={linkContainer}
							>
								<Link
									prefetch={false}
									href={item?.destinationUrl || "/"}
								>
									<a>{item?.label}</a>
								</Link>
							</div>
						))}
					</div>
					<div
						className={styles.next}
						onClick={next}
						role="button"
						onKeyPress={next}
						tabIndex={0}
					>
						&rarr;
					</div>
				</div>
			)}
		</>
	);
};

export default SlideCustomText;
